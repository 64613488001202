import React, { useState } from "react";

import DefaultLayout from "../../../layouts/default";

const tacs = () => {
  const [akkordeonOpen, setAkkordeonOpen] = useState(false);
  const [akkordeon2Open, setAkkordeon2Open] = useState(false);

  return (
    <DefaultLayout title="WiCare|now tacs">
      <div className="white wicare">
        <div className="navContentSpacerHelper"></div>
        <header className="navWrapper">
          <nav className="mainNav">
            <div className="mainNavBackgroundHelper"></div>
            <div className="logo">
              <a href="/">
                <img
                  src="/assets/images/logos/wigasoft-logo.svg"
                  alt="WigaSoft Logo"
                  width="175"
                />
              </a>
            </div>
            <div className="mainNav-opener"></div>
            <ul className="mainLinks">
              <li>
                <a href="/news/">News</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/heime/">Heime</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/rehabilitation/">
                  Rehabilitation
                </a>
              </li>
              <li>
                <a href="/portrait/">Über uns</a>
              </li>
              <li>
                <a href="/support/">Support</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/wigasoft-ag">
                  <div className="linkedInIcon" />
                </a>
              </li>
            </ul>
          </nav>

          <nav className="subNav2">
            <div className="subNav2Product">
              <div className="productImage">
                <img
                  src="/assets/images/logos/WCN-tacs.svg"
                  alt="WiCare tacs Logo"
                  width="40"
                />
              </div>
              <div className="productText">
                <span className="line">WiCare|now tacs</span>
                <span className="line">&nbsp;das Controlling im Griff</span>
              </div>
            </div>
            <div className="subNav2-opener"></div>
            <ul className="subNav2Links">
              <li className="is-active">
                <a href="/dokumentationsloesungen/wicare-tacs/">Übersicht</a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-tacs/technik/">
                  Technik
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-tacs/referenzen/">
                  Referenzen
                </a>
              </li>
              <li>
                <a href="/dokumentationsloesungen/wicare-tacs/downloads/">
                  Downloads
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="menuBackground">
          <section className="mainBanner bannerWeiss">
            <div className="wrapper has-padding-top has-padding-bottom">
              <div className="bannerFlex">
                <div>
                  <img
                    className="logo-product"
                    src="/assets/images/logos/WCN-tacs.svg"
                  />
                </div>
                <div className="bannerPadding">
                  <h1>
                    WiCare|now tacs<sup>&#174;</sup>
                  </h1>
                  <h3 className="padding-25-bottom">
                    das Controlling im Griff
                  </h3>
                  <p className=" bannerText">
                    WiCare|now tacs ist ein Controllingsystem zur
                    Tätigkeitsanalyse mit integrierter Leistungserfassung und
                    Abbildung der effektiven finanziellen Konsequenzen.
                    WiCare|now tacs eignet sich für den Einsatz bei
                    verschiedenen Berufsgruppen.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="wrapper">
            <section>
              <h3 className="smallStaticPadding-bottom h3Bold text-centered-td">
                WiCare|now tacs<sup>&#174;</sup>
              </h3>
              <p className="fliesstextLead smallStaticPadding-bottom text-centered-td">
                WiCare|tacs war 2006 die erste Softwarelösung für die Erfassung
                der Methode tacs.
              </p>
              <p className="fliesstextLead text-centered-td">
                Die neue Generation, WiCare|now tacs, ist eine komplette,
                webbasierte Neuentwicklung, welche unser alt bewährtes
                WiCare|tacs ablöst. WiCare|now tacs ist rodix tacs® V2
                zertifiziert.
              </p>
            </section>
          </div>

          <div className="wrapper mobile-tablet-no-padding">
            <section className="cContainer is-background-color-violet is-color-white rounded">
              <h4 className="smallStaticPadding-bottom text-centered-td h4Bold">
                Best Features
              </h4>
              <div className="ccHalfFlex">
                <div>
                  <ul className="featuresList">
                    <li>Webbasiert, plattformunabhängig, integrierbar</li>
                    <li>
                      Methodenkonform, rodix tacs<sup>®</sup> V2 zertifiziert
                    </li>
                    <li>Datenexport an rodix fürAuswertungen</li>
                    <li>
                      bietet verschiedene Erfassungsmöglichkeiten (einzelne
                      Variablen, individuelle Blöcke, Gruppen)
                    </li>
                    <li>
                      ermöglicht rollenbasierte Zugriffe auf Funktionalitäten
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="featuresList">
                    <li>
                      Erfahrung mit der Verwaltung von verschiedenen Standorten
                    </li>
                    <li>
                      Grundlage für Stellenbewirtschaftung, Arbeitspläne und
                      Patientenzuteilung
                    </li>
                    <li>
                      Mit weiteren WiCare|now-Komponenten erweiterbar (z.B.
                      CHOP99.C1)
                    </li>
                    <li>
                      Leistungsdaten aus der elektronischen Pflegedokumentation
                      WiCare|Doc oder anderen Klinikinformationssystemen können
                      übernommen werden
                    </li>
                    <li>
                      Schnittstellen zu ERP-Systemen und zur
                      Personaleinsatzplanung erfolgreich im Einsatz
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>

          <div className="wrapper wrapper-xl">
            <section className="margin-45-bottom">
              <div
                className={
                  akkordeonOpen
                    ? "funktionenToggle is-background-color-violet selected"
                    : "funktionenToggle is-background-color-violet"
                }
                onClick={() => setAkkordeonOpen(!akkordeonOpen)}
              >
                <h3>Funktionen</h3>
                <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
              </div>
              <div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Uebersicht.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Übersicht"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Uebersicht.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Übersicht</h4>
                    <p className="fliesstext">
                      Über das tacs-Board steigt der Mitarbeiter ein und
                      dokumentiert seine erbrachten Leistungen entsprechend den
                      vier Elementen Kunde, Organisation, Bildung und Absenzen.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Praesenzzeit.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Präsenzzeit"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Praesenzzeit.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Präsenzzeit</h4>
                    <p className="fliesstext">
                      Die verfügbare zeitliche Ressource wird den dokumentierten
                      Tätigkeiten gegenübergestellt. Die tägliche Präsenzzeit
                      wird via Schnittstelle aus der Planung übernommen oder
                      manuell erfasst.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Ebene_Kunde.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Kunde"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Ebene_Kunde.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Kunde</h4>
                    <p className="fliesstext">
                      Alle erbrachten Kunden-Leistungen werden detailliert im
                      Element B "Kunden" erfasst. Erbrachten Gruppen-Leistungen
                      können im Ganzen oder anteilsmässig einfach erfasst
                      werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Organisation.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Organisation"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Organisation.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Organisation</h4>
                    <p className="fliesstext">
                      Administrative Leistungen, bzw. Aufgaben, werden
                      stationsbezogen im Element C "Organisation" erfasst.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
                <div className="dottedBorder"></div>
                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Wissensvermittlung.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Bildung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Wissensvermittlung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Bildung</h4>
                    <p className="fliesstext">
                      Im Element D "Bildung", werden die erbrachten bzw.
                      erhaltenen Aus-, Fort und Weiterbildungs-Leistungen
                      erfasst. Die erbrachte Lehrtätigkeit kann einfach
                      mitarbeiterbezogen dokumentiert werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Praesenzzeit.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Absenzen"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Praesenzzeit.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Absenzen</h4>
                    <p className="fliesstext">
                      Die Abwesenheitszeiten wie z.B. Krankheit werden im
                      Element E "Absenzen" erfasst.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/subjektive_Arbeitsauslastung.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Subjektive Arbeitsbelastung"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/subjektive_Arbeitsauslastung.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Subjektive Arbeitsbelastung</h4>
                    <p className="fliesstext">
                      Der Anwender schliesst seinen Erfassungstag durch eine
                      persönliche Einschätzung der Arbeitsbelastung zwischen
                      «sehr hoch» und «sehr tief» ab. Diese kann auch
                      kommentiert werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Monatsabschluss.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Monatsabschluss"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Monatsabschluss.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Monatsabschluss</h4>
                    <p className="fliesstext">
                      Bevor die erfassten Leistungen an das Informations- und
                      Steuerungs-Tool tacs®Reporting übermittelt werden, muss
                      der Monat durch den Anwenderbetrieb validiert und
                      freigegeben werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>

                <div className="dottedBorder"></div>

                <article className="mobilUnterwegs">
                  <div className="imgDiv text-centered">
                    <a
                      href="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Auswertungen.png?auto=format,compress"
                      data-lightbox="tacs-1"
                      data-actual-title="Auswertungen"
                    >
                      <img src="https://wigasoft-prod.imgix.net/screenshots/tacs/2021/Auswertungen.png?auto=format,compress" />
                    </a>
                  </div>
                  <div className="textDiv text-centered-m">
                    <h4 className="h4Bold">Auswertungen</h4>
                    <p className="fliesstext">
                      WiCare|now tacs ist von der Firma{" "}
                      <a href="www.rodix.ch" target="_blank">
                        rodix
                      </a>{" "}
                      für Version 2 zertifiziert. Die Auswertungen werden in dem
                      von der Firma rodix bereitgestellten Portal
                      sichergestellt.
                      <br />
                      <br />
                      Weitere Auswertungen können eingepflegt werden.
                    </p>
                    <div className="devices">
                      <img
                        src="/assets/images/icons/Icon_Desktop.jpg"
                        title="Desktop"
                      />
                      <img
                        src="/assets/images/icons/Icon_Tablet.jpg"
                        title="Tablet"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </section>
          </div>

          <section className="nutzen">
            <div
              className={
                akkordeon2Open
                  ? "funktionenToggle is-background-color-violet selected"
                  : "funktionenToggle is-background-color-violet"
              }
              onClick={() => setAkkordeon2Open(!akkordeon2Open)}
            >
              <h3>Nutzen</h3>
              <img src="/assets/images/gestaltungselemente/akkordeon-arrow.svg" />
            </div>
            <div>
              <div className="wrapper">
                <div className="selection has-padding-top">
                  <div className="tab-select selected" id="select1">
                    <h4>User</h4>
                  </div>
                  <div className="tab-select" id="select2">
                    <h4>Management</h4>
                  </div>
                  <div className="tab-select" id="select3">
                    <h4>IT</h4>
                  </div>
                </div>
              </div>
              <div className="content is-background-color-grey">
                <div className="wrapper">
                  <div
                    id="content1"
                    className="selected has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Übersichtlich und klar</h4>
                      <p className="fliesstext7">
                        Die Mitarbeiter können die tägliche Erfassung
                        übersichtlich und klar dokumentieren.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Methodenkonforme Tätigkeitserfassung über die
                          Navigation in den Belegen, einzelnen Variablen, oder
                          als Gruppe
                        </li>
                        <li>
                          Rollenbasierend – dem Anwender können unterschiedliche
                          Rollen zugeordnet werden
                        </li>
                        <li>
                          Mehrfachanstellung – zwischen der Mehrfachanstellung
                          kann per Klick ohne An- und Abmeldung gewechselt
                          werden
                        </li>
                        <li>
                          Übernahme dokumentierten Handlungen aus WiCare|Doc und
                          anderen Klinikinformationssystem
                        </li>
                        <li>
                          Zuordnung von Lernenden bei der Variable
                          "Wissensvermittlung"
                        </li>
                        <li>
                          Umbuchen von falsch erfassten Variablen auf einen
                          anderen Fall, Datum oder eine andere
                          Organisationseinheit
                        </li>
                        <li>Kontrollieren und validieren der Erfassung</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="content2"
                    className="has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Integrierbar</h4>
                      <p className="fliesstextLead">
                        WiCare|now tacs liefert die Grundlagen für Analysen,
                        Prognosen und Vergleiche.
                        <br />
                        <br />
                        WiCare|now tacs lässt sich einfach an bestehende
                        Klinikinformationslösungen anbinden und integrieren.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Webbasierend, Geräte und Betriebssystemunabhängig
                        </li>
                        <li>
                          Lösung auf dem aktuellsten Stand der Software-Technik
                        </li>
                        <li>Liefert Kennzahlen für den Benchmark</li>
                        <li>Eignet sich für verschiedene Berufsgruppen</li>
                        <li>
                          Eigene Auswertungen können innerhalb WiCare|now tacs
                          bereitgestellt werden
                        </li>
                        <li>
                          Zugriff auf weitere Komponenten von WiCare|now wie zum
                          Bespiel "Pflege-Komplexbehandlung nach CHOP 99" können
                          jederzeit zur Verfügung gestellt und freigeschaltet
                          werden
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    id="content3"
                    className="has-padding-top has-padding-bottom"
                  >
                    <div>
                      <h4 className="h4Bold">Standardisiert und normiert</h4>
                      <p className="fliesstextLead">
                        WiCare|now tacs passt sich in die vorgegebene
                        IT-Landschaft ein. Die Orientierung an gängigen
                        Standards wie z.B. ALIS und HL7 helfen bei der einfachen
                        Integration von Dritt-Systemen. Die Kompatibilität mit
                        den neusten Windows Server- und Client-Betriebssystemen
                        sowie den aktuellen Versionen von Microsoft SQL Server
                        und Datenbanksysteme erlauben den Betrieb stabiler und
                        ausbaufähiger Systeme, die sich nahtlos in
                        Standard-Netzwerke einbinden lassen.
                      </p>
                    </div>
                    <div>
                      <ul className="featuresList">
                        <li>
                          Webbasierend, Geräte- und Betriebssystemunabhängig
                        </li>
                        <li>für mobile Geräte (Tablets) optimiert</li>
                        <li>
                          Aufruf aus oder Integration in Drittapplikationen via
                          URL möglich
                        </li>
                        <li>ActiveDirectory und SingleSignOn unterstützt</li>
                        <li>
                          verfügt mit WiCare|now Admin über ein einfach zu
                          bedienendes Konfigurationsmodul
                        </li>
                        <li>
                          stellt ein flexibel konfigurierbares
                          Schnittstellenmodul bereit
                        </li>
                        <li>
                          basiert auf einer strukturiert aufgebauten Datenbank
                          mit einfach lesbarem Datenmodell
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="wrapper">
              <div className="bContainer purple margin-45-bottom">
                <h5>Integrierbar</h5>
                <p>
                  Die bereitgestellten WiCare|now tacs-Komponenten können als
                  Webfacette einfach in bestehende Dokumentationssysteme
                  integriert werden.
                </p>
              </div>
              <div className="has-margin-bottom text-centered">
                <img
                  width="250px"
                  src="/assets/images/logos/_tacs_inside_CMYK_pos.png"
                />
              </div>
              <div className="has-margin-bottom">
                <p className="fliesstextS is-color-grey text-centered margin-0-auto">
                  tacs<sup>®</sup> ist ein eingetragenes Warenzeichen der Firma{" "}
                  <a href="https://www.rodix.ch/" target="_blank">
                    Rodix Reto Odermatt GmbH
                  </a>
                  . Alle weiteren Waren- und Markenzeichen unterliegen
                  uneingeschränkt den Bestimmungen gültiger Kennzeichenrechte
                  sowie den Bestimmungen der jeweiligen Eigentümer.
                </p>
              </div>
            </div>
          </section>

              <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

          <footer className="mainFooter">
            <div className="wrapper">
              <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
                <ul>
                  <li>
                    <a href="/">Startseite</a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|tacs<sup>&#174;</sup>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footerNotAnotherFlexboxButAGrid linklist">
                <div className="flex-item1">
                  <ul>
                    <li>
                      <a href="/dokumentationsloesungen/heime/">
                        <strong>Heime</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/spitaeler/">
                        <strong>Spitäler</strong>
                      </a>
                    </li>
                    <li>
                      <a href="/dokumentationsloesungen/rehabilitation/">
                        <strong>Rehabilitation</strong>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item2">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L BESA
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -L RAI-NH
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-b/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -B
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-h/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -H
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-doc-r/">
                        <span className="is-visible-desktop">WiCare|Doc</span>
                        <span className="is-visible-tablet-until-PC">WCD</span>
                        -R
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item3">
                  <ul>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-tacs/">
                        WiCare|now tacs<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-lep/">
                        WiCare|now LEP<sup>&#174;</sup>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/dokumentationsloesungen/wicare-le/">
                        WiCare|now LE
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item4">
                  <ul>
                    <li>
                      <a href="/portrait/">
                        <strong>Über uns</strong>
                      </a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/lehrlinge/">Lernende</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/partner/">Partner</a>
                    </li>
                    <li className="partiallyHidden">
                      <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                    </li>
                    <li>
                      <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                    </li>
                  </ul>
                </div>
                <div className="flex-item5">
                  <ul>
                    <li>
                      <a href="/support/">
                        <strong>Support</strong>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row copyright">
              <div className="wrapper">
                <div className="column">
                  © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
                </div>
                <div className="column has-padding-left">
                  <ul>
                    <li>
                      <a href="/datenschutz/">Datenschutzerklärung</a>
                    </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                  </ul>
                </div>
                <div className="column align-right">
                  <a href="/portrait/kontakt/" className="anfahrt">
                    <img
                      id="location-pointer"
                      src="/assets/images/icons/icon-anfahrt.svg"
                    />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default tacs;
